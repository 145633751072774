<template>
  <header>
    <div class="container">
      <div class="logo">
        <router-link to="/">
            <img src="@/assets/images/logo.svg" alt="logo">
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
export default {

}
</script>

<style>

</style>