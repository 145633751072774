<template>
  <footer>
      <div class="container">
        <div class="content">
          <ul>
            <li>
              <a href="https://www.benjaminsimier.com" target="_blank">
                www.benjaminsimier.com
              </a>
            </li>
            <li>
              <a href="https://github.com/benjaminsimier/bob" target="_blank" rel="noopener noreferrer">
                Project on Github
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>